import {
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AvatarComponent from "../components/avatar";
import CustomizedSnackbars from "../components/snackbar";
import {
  useGetAdminQuery,
  useGetRolesQuery,
  useUpdateAdminMutation,
} from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";
const AdminEditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateAdmin, { isSuccess, data: adminDataRes }] =
    useUpdateAdminMutation();
  const { data, isLoading: userLoading } = useGetAdminQuery(id);
  const [roleDetails, setRoleDetails] = React.useState({});
  const defaultData = { name: "", roleId: "", email: "", photoUrl: "" };
  const [formData, setFormData] = React.useState(defaultData);
  const { data: rolesData, isLoading: roleLoading } = useGetRolesQuery();
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  React.useEffect(() => {
    if (data?.status == "SUCCESS") {
      setFormData({
        email: data.result[0].email,
        name: data.result[0].adminName,
        roleId: data.result[0].roleId,
        photoUrl: data.result[0].photoUrl,
        id: data.result[0].id
      });
    }
    if (rolesData?.status == "SUCCESS") {
      const userData = rolesData?.result.data;
      userData?.forEach((item) =>
        setRoleDetails((role) => {
          return { ...role, [item.roleId]: item.roleName };
        })
      );
    }
    if (adminDataRes?.status == "SUCCESS") {

      dispatch(updateCommonData({
        message: "Admin user successfully updated",
        title: "Success",
        img: "/success.png",
        status: true
      }));
      navigate(ROUTES.ADMIN_USERS);
      // setSnackbar({
      //   message: adminDataRes?.message,
      //   severity: "success",
      //   open: true,
      // });
      // setTimeout(() => {

      // }, 1500);
    }
  }, [roleLoading, userLoading, isSuccess]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    formData.roleId = +formData.roleId;

    const formData1 = new FormData();

    Object.keys(formData).forEach((key) => {
      formData1.append(key, formData[key]);
    });

    updateAdmin(formData1);
  };
  return (
    <Box>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction={"column"} gap={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Edit User
          </Typography>
        </Stack>
        <Box
          p={3}
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            overflow: 'hidden',
          }}
        >
          <Stack gap={3} justifyContent={"center"}>
            <Stack direction={"row"} alignItems={"center"} gap={1.25}>
              <AvatarComponent
                width={28}
                height={28}
                sx={{
                  color: '#000',
                }}
              />
              <Typography variant="h4" className="subtitle1">User Detail</Typography>
              <Link to={`${ROUTES.ADMIN_USERS}${ROUTES.CHANGE_PASSWORD}/${id}`} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: '500', position: 'relative', bottom: '-2px' }}>Change Password</Link>
            </Stack>
            <div className="row flex-column gap-3">
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                <FormLabel>Name</FormLabel>
                <OutlinedInput
                  className="w-100"
                  placeholder="Enter name of the user"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                <FormLabel>Email</FormLabel>
                <OutlinedInput
                  className="w-100"
                  placeholder="Enter user email"
                  value={formData.email}
                  name="email"
                  disabled
                  readOnly
                />
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                <FormLabel required>Upload Profile Image</FormLabel>
                <div
                  style={{
                    border: '1px solid #aaa',
                    borderRadius: '8px',
                    padding: '3px',
                    height: '38px'
                  }}
                >
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Button
                      component="label"

                      variant="contained"
                      tabIndex={-1}
                      style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", color: " rgba(0, 0, 0, 0.5)", fontSize: '13px', fontWeight: '500', lineHeight: '17.2px' }}
                    >
                      Choose File
                    </Button>
                    <input
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                      type="file"
                      name="photoUrl"
                      onChange={(e) => {
                        // formik.handleChange({
                        //   target: {
                        //     name: "photoUrl",
                        //     value: e.target.files[0]
                        //   }
                        // })
                        setFormData({ ...formData, [e.target.name]: e.target.files[0] });
                      }}
                    />

                    {formData?.photoUrl?.name && <p className="m-0">{formData?.photoUrl?.name}</p>}
                  </div>
                </div>
                <small style={{ fontSize: '11px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 80x80 and 100x100.</small>


              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12">
                {rolesData?.status == "SUCCESS" && (
                  <>
                    <FormLabel>Roles</FormLabel>
                    <Select
                      className="w-100"
                      value={formData && formData.roleId ? formData.roleId : ""}
                      name="roleId"
                      placeholder="Select"
                      onChange={handleChange}
                    >
                      {roleDetails &&
                        Object.keys(roleDetails).length > 0 &&
                        Object.keys(roleDetails).map(
                          (name) =>
                            name && (
                              <MenuItem key={name} value={name}>
                                {roleDetails[name]}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </>
                )}
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-12 col-12 gap-2 d-flex flex-wrap justify-content-end">
                <button className="btnSecondary" onClick={() => navigate(-1)}>Cancel</button>
                <button className="btnPrimary" onClick={() => handleSubmit()}>Update User</button>
              </div>
            </div>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default AdminEditUser;
