import {
  Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
  Box,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import SearchInput from "../../../components/inputs/searchInput";
import PaginationSelect from "../../../components/select/pagination";
import useDebounce from "../../../hooks/use.debounce";
import { formatPrice, getFormatedDate } from "../../../utils/common";
import { SUBSCRIPTION_PRODUCT_TYPE, SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPE } from "../../../utils/constant";
import { getAllBrandForDropDown } from "../../brands/action";
import { getAllSubscriptions, updateSubscriptionStatus } from "../action";
import { resetSubscription, updateSubscription } from "../subscriptionSlice";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isSticky } = props;
  const createSortHandler =
    (property) => (event) => {
      if (property === "action") {
        return;
      }
      onRequestSort(event, property);
    };

  const headCells = [
    {
      id: "plan",
      numeric: false,
      disablePadding: true,
      label: "Plan Name",
      type: "",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Plan Type",
      type: "",
    },
    {
      id: "products",
      numeric: false,
      disablePadding: true,
      label: "Type of Product",
      type: "",
    },
    {
      id: "Discount",
      numeric: false,
      disablePadding: true,
      label: "Discount(%)",
      type: "",
    },
    {
      id: "monthly",
      numeric: false,
      disablePadding: true,
      label: "Monthly(₹)",
      type: "",
    },

    {
      id: "quarterly",
      numeric: false,
      disablePadding: true,
      label: "Quarterly(₹)",
      type: "",
    },

    {
      id: "yearly",
      numeric: false,
      disablePadding: true,
      label: "Yearly(₹)",
      type: "",
    },
    {
      id: "lastUpdate",
      numeric: false,
      disablePadding: true,
      label: "Created Date",
      type: "",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      type: "",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      type: "",
    },
  ];

  // headCell.type == '' || ([headCell.type].includes(subType)) ?
  return (
    <TableHead className={`hideActionSort ${isSticky} `}>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell?.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                IconComponent={null}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}


export default function Subscriptions(props) {
  const navigate = useNavigate();
  const states = useSelector((state) => state.app);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [open, setOpen] = useState(false);
  const [sticky, setSticky] = useState("");
  const [status, setStatus] = useState({ name: "", status: "", id: "" });
  const dispatch = useDispatch();
  const {
    limit,
    page,
    sort_key,
    totalCount,
    totalPages,
    filters,
    listing,
  } = useSelector((state) => state.subscriptionSlice);
  const debouncedSearch = useDebounce(filters.name, 500);
  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // if (property === "status") return;
    dispatch(
      updateSubscription({
        sort_type: isAsc ? "asc" : "desc",
        sort_key: property,
      }),
    );
    dispatch(getAllBrandForDropDown());
  };

  useEffect(() => {
    if (debouncedSearch || filters.name == "")
      dispatch(getAllSubscriptions())
  }, [debouncedSearch]);

  useEffect(() => {
    dispatch(getAllSubscriptions())
    return () => dispatch(resetSubscription())
  }, []);

  const handleChangePerPage = (event, value) => {
    dispatch(updateSubscription({ limit: event.target.value, page: 0 }));
    dispatch(getAllSubscriptions());
  };

  const handleChange = (event, value) => {
    dispatch(updateSubscription({ page: value - 1 }));
    dispatch(getAllSubscriptions());
  };

  const handleSearch = (event) => {
    //if (event.target.value)
    dispatch(updateSubscription({ filters: { ...filters, name: event.target.value } }));
  };

  const handleSearchClear = () => {
    dispatch(updateSubscription({ filters: { ...filters, name: "" } }));
    dispatch(getAllSubscriptions());
  };
  const tableRef = useRef();
  // useEffect(() => {
  //   const tableContainer = tableRef.current;
  //   const handleScroll = () => {

  //     if (tableContainer) {
  //       const { scrollTop, clientHeight, scrollHeight } = tableContainer;
  //       console.log("scroll", Math.ceil(scrollTop + clientHeight), scrollHeight - 1);
  //       if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
  //         dispatch(updateSubscription({ limit: limit + 10, page: 0 }));
  //         dispatch(getAllSubscriptions())
  //       }
  //     }

  //   };

  //   tableContainer.addEventListener('scroll', handleScroll);

  //   return () => {
  //     tableContainer.removeEventListener('scroll', handleScroll);
  //   };
  // });
  // const isSticky = () => {
  //   const scrollTop = window.scrollY;
  //   const stickyClass = scrollTop >= 150 ? "is-sticky" : "";
  //   setSticky(stickyClass);
  //   console.log("stickyClass", stickyClass, scrollTop);
  // };
  // useEffect(() => {
  //   console.log("hello");
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // }, []);
  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Stack
          className="mainTitle flex-wrap"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography className="page-title" variant="h1"> Subscription Plan  </Typography>
          <button className={states?.permissions?.subscription?.create != 1 ? "btnDisabled" : "btnPrimary"}
            disabled={states?.permissions?.subscription?.create != 1}
            onClick={() => navigate("/subscription/create")}
          >
            Create Plan
          </button>
        </Stack>
        <Box
          sx={{
            backgroundColor: '#fff',
            transition: 'all .5s ease-in-out',
            position: 'relative',
            border: '1px solid rgba(170, 170, 170, 0.3)',
            borderRadius: '12px',
            boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
            // overflow: 'hidden',
          }}>
          <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
            <SearchInput
              className="inputSearch sContrl p-0"
              value={filters.name}
              placeholder={"Search here..."}
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
              startAdornment={
                <InputAdornment className="overIcon rolesSearch" position="start"
                  sx={{
                    maxHeight: 'none',
                    height: '38px',
                    minWidth: '38px',
                    marginRight: '0px'
                  }}
                >
                  <Search />
                </InputAdornment>
              }
            />
            <div className='allFilter cmpyMgmt '>

              <Stack direction={"row"} gap={0.5}>
                <Select
                  value={filters?.type || ''}
                  name="status"
                  onChange={(e) => {
                    dispatch(updateSubscription({ filters: { ...filters, type: e.target.value } }));
                    dispatch(getAllSubscriptions());
                  }} displayEmpty
                  defaultValue={""}
                  justifyContent={"flex-end"}
                  className="upperCase"
                >
                  <MenuItem disabled value="">
                    Plan Type
                  </MenuItem>

                  {SUBSCRIPTION_TYPE.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                    {v}
                  </MenuItem>)}
                </Select>
                <Select
                  value={filters?.typeOfProducts || ''}
                  name="status"
                  onChange={(e) => {
                    dispatch(updateSubscription({ filters: { ...filters, typeOfProducts: e.target.value } }));
                    dispatch(getAllSubscriptions());
                  }} displayEmpty
                  defaultValue={""}
                  justifyContent={"flex-end"}
                  className="upperCase"
                >
                  <MenuItem disabled value="">
                    Type of Product
                  </MenuItem>

                  {SUBSCRIPTION_PRODUCT_TYPE.map((v) => <MenuItem className="upperCase" key={v} value={v} >
                    {v}
                  </MenuItem>)}
                </Select>
                <Tooltip title="Refresh listing">
                  <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                    onClick={() => {
                      dispatch(updateSubscription({ filters: {} }))
                      dispatch(getAllSubscriptions());
                    }} />
                </Tooltip>
              </Stack>

            </div>


          </div>


          <TableContainer ref={tableRef}>
            <Table
              stickyHeader aria-label="sticky table"
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                isSticky={sticky}
                order={order}
                orderBy={sort_key}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                //rowCount={rows.length}
                numSelected={0}
                onSelectAllClick={function (

                ) {
                  throw new Error("Function not implemented.");
                }}
              //order={"desc"}
              />
              <TableBody>
                {listing && listing?.length ? (
                  listing?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        className={"tableRow"}
                        style={{ userSelect: "text" }}
                      >
                        <TableCell component="td" scope="row">
                          {row?.name || "N/A"}

                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row?.type || "N/A"}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row?.typeOfProducts || "N/A"}
                        </TableCell>

                        <TableCell align="left">
                          {row?.totalDiscount || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.monthlyDiscountedRate ? formatPrice(row?.monthlyDiscountedRate) : (formatPrice(row?.monthlyRate) || "N/A")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.quarterlyDiscountedRate ? formatPrice(row?.quarterlyDiscountedRate) : (formatPrice(row?.quarterlyRate) || "N/A")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.yearlyDiscountedRate ? formatPrice(row?.yearlyDiscountedRate) : (formatPrice(row?.yearlyRate) || "N/A")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.updatedDate ? getFormatedDate(row?.updatedDate, "DD MMMM YYYY") : "N/A"}

                        </TableCell>
                        <TableCell align="left">
                          {row.status ? <button className={row.status?.toLowerCase()}>{row.status || "N/A"}</button> : "N/A"}
                        </TableCell>
                        <TableCell sx={{ cursor: "pointer" }}>
                          <Stack direction={"row"}
                            sx={{
                              gap: '8px'
                            }}
                          >
                            <div className="actBtnGrps">
                              {states?.permissions?.subscriptions?.edit == 1 && row.status === SUBSCRIPTION_STATUS.DRAFT && <span className="editIcon" onClick={() => navigate(`/subscription/edit/${row.id}`)}>Edit</span>}
                              <span className="viewIcon" onClick={() => navigate("/subscription/details/" + row.id)}>View</span>
                              {row.status !== SUBSCRIPTION_STATUS.DISABLE && <>
                                <span style={{ color: `${row.status === SUBSCRIPTION_STATUS.DRAFT ? "#22c55e" : "#ef3f3f"}` }} onClick={() => {
                                  setStatus({ name: row.name, status: row.status === SUBSCRIPTION_STATUS.DRAFT ? SUBSCRIPTION_STATUS.LIVE : SUBSCRIPTION_STATUS.DISABLE, id: row.id });
                                  setOpen(true)
                                }}>{row.status === SUBSCRIPTION_STATUS.DRAFT ? "Activate" : "Disable"}</span></>}
                            </div>

                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        alignItems={"center"}
                        component="h3"
                      >
                        No Data found!
                      </Typography>

                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </TableContainer>
          <Stack
            sx={{
              background: 'transparent',
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
              <Box className="d-flex align-items-center rowPerPage" gap={0}>
                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
              </Box>
              <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                <Typography variant="subtitle2" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>

                  {listing && listing.length > 0
                    ? page === 0
                      ? `0 - ${listing.length}`
                      : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                      } `
                    : "0 - 0"}{" "}
                  &nbsp;of {totalCount} items
                </Typography>
                <Pagination
                  count={Math.ceil(totalPages / limit) || 0}
                  variant="outlined"
                  onChange={handleChange}
                  defaultPage={1} siblingCount={0} boundaryCount={1}
                />
              </Box>
            </Box>

          </Stack>

          <CommonModel
            open={open}
            handleDialogClose={() => setOpen(false)}
            dialogTitle={""}
            dialogPara={`Are you sure, you want to ${status.status} this “${status.name}” plan ? `}
            nvYes={"Confirm"}
            nvCancel={"Cancel"}
            yesHandle={() => {
              dispatch(updateSubscriptionStatus(status.id, status.status));
              setOpen(false);
            }}
          />
        </Box>




      </Stack>

    </>
  );
}
