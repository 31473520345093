import {
    Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Box, InputAdornment, MenuItem, Pagination, Select, Stack, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AutoSuggestion from "../../components/autocomplete";
import DateRangePickerComponent from "../../components/date/DateRangePickerComponent";
import CommonModel from '../../components/dialog/Common';
import SearchInput from '../../components/inputs/searchInput';
import PaginationSelect from '../../components/select/pagination';
import useDebounce from "../../hooks/use.debounce";
import { capitalizeFirstLetter, getFormatedDate, getRedirectlink, parseJSONorString, uniqueArrayByKey } from '../../utils/common';
import { updateCampaign } from "../campaigns/campaignSlice";
import { updateUser } from "../users/userSlice";
import { getAdminUserListing, getAllAuditListing, getCampaignAndinfluencersData } from './action';
import { updateAudit } from './auditSlice';
function EnhancedTableHead(props) {
    const { order, orderBy } = props;


    const headCells = [

        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "User Name",
            type: "",
        },
        {
            id: "recort_type",
            numeric: false,
            disablePadding: true,
            label: "Record Type",
            type: "",
        },
        {
            id: "Record",
            numeric: false,
            disablePadding: true,
            label: "Record Name",
            type: "",
        },
        {
            id: "Field Name",
            numeric: false,
            disablePadding: true,
            label: "Field Name",
            type: "",
        },

        {
            id: "Time Stamp",
            numeric: false,
            disablePadding: true,
            label: "Time Stamp",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={() => null}
                            //onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


const AuditTrails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modelOpen, setModelOpen] = useState(false);
    const [jsonData, setJsonData] = useState('');
    const tableRef = useRef();
    const {
        graphData,
        limit,
        page,
        search,
        sort_key,
        totalCount,
        totalPages,
        listing,
        filters,
        userListing,
        removeUsers
    } = useSelector((state) => state.auditSlice);
    const {
        triggerTable
    } = useSelector((state) => state.commonSlice)
    const debouncedSearch = useDebounce(filters.search, 500);
    useEffect(() => {
        dispatch(getAdminUserListing());
        dispatch(getCampaignAndinfluencersData());
        dispatch(getAllAuditListing());
    }, [])
    const handleChangePerPage = (event, value) => {
        dispatch(updateAudit({ limit: event.target.value, page: 0 }));
        dispatch(getAllAuditListing());
    };
    const handleChange = (event, value) => {
        dispatch(updateAudit({ page: value - 1 }));
        dispatch(getAllAuditListing());
    };
    const handleSearch = (event) => {
        if (event.target.value) {
            dispatch(updateAudit({ filters: { ...filters, search: event.target.value }, page: 0 }));
        } else {
            dispatch(updateAudit({ filters: { ...filters, search: "" }, page: 0 }));
            dispatch(getAllAuditListing());
        }

    };

    const handleSearchClear = () => {
        dispatch(updateAudit({ filters: { ...filters, search: "" } }));
        dispatch(getAllAuditListing());
    };
    useEffect(() => {
        if (debouncedSearch)
            dispatch(getAllAuditListing())
    }, [debouncedSearch, dispatch]);



    const data = {
        options: {
            labels: graphData?.campaign?.map((v) => v.status) || [],
            chart: {
                width: '100%',
                dataLabels: {
                    formatter: function (val, opts) {
                        let name = opts.w.globals.labels[opts.seriesIndex];
                        let highlighted = (opts.seriesIndex === opts.dataPointIndex) ? "highlighted" : "";
                        return `<span class="${highlighted}">${name}</span>: ${val} <i class='fas fa-arrow-right'></i>`;
                    }
                },
                type: 'pie',
                background: '#f5f5f5',
                events: {
                    click: function (event, chartContext, config) {
                        if (typeof config.dataPointIndex !== 'undefined' && config.dataPointIndex >= 0) {
                            const dataPointIndex = config.dataPointIndex;
                            const clickedLabel = chartContext.w.globals.labels[dataPointIndex];
                            console.log("clickedLabel", clickedLabel)
                            // Dispatch action and navigate
                            dispatch(updateCampaign({ filters: { status: clickedLabel.charAt(0).toUpperCase() + clickedLabel.slice(1).toLowerCase() } }));
                            navigate("/campaign-management");
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    size: '100%',
                },
            },

        },

        series: graphData?.campaign?.map((v) => v.count) || [],
    };

    const influencerData = {
        options: {
            labels: graphData?.influencer?.map((v) => v.status) || [],
            chart: {
                width: '100%',
                dataLabels: {
                    formatter: function (val, opts) {
                        let name = opts.w.globals.labels[opts.seriesIndex];
                        let highlighted = (opts.seriesIndex === opts.dataPointIndex) ? "highlighted" : "";
                        return `<span class="${highlighted}">${name}</span>: ${val} <i class='fas fa-arrow-right'></i>`;
                    }
                },
                type: 'donut',
                background: '#f5f5f5',
                events: {
                    click: function (event, chartContext, config) {
                        if (typeof config.dataPointIndex !== 'undefined' && config.dataPointIndex >= 0) {
                            const dataPointIndex = config?.dataPointIndex;
                            const clickedLabel = chartContext?.w?.globals?.labels[dataPointIndex];

                            dispatch(updateUser({ filters: { status: clickedLabel.charAt(0).toUpperCase() + clickedLabel.slice(1).toLowerCase() } }));
                            navigate("/userManagement");
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    size: '100%',
                },

            },
            colors: ['#F7CB73', '#06C325', '#F14C5F'],
            fill: {
                colors: ['#F7CB73', '#06C325', '#F14C5F']
            },
        },
        series: graphData?.influencer?.map((v) => v.count) || [],
    };

    const parseJson = (data) => {
        for (let key in data) {
            //console.log("data", data);

            if (data[key] == "object") {
                parseJson(data[key]);
            } else {
                console.log("key", key)
                console.log("value", data[key]);
            }
        }
    }

    //console.log(parseJson(parseJSONorString(jsonData?.newValue)))
    const Details = () => {
        console.log("parseJSONorString(jsonData?.newValue)", parseJSONorString(jsonData?.newValue))
        return <TableContainer stickyHeader >
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Field Name</TableCell>
                        <TableCell align="left">Old Value</TableCell>
                        <TableCell align="left">New Value</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow >
                        <TableCell align="left" className="capitalize-first-letter">
                            {jsonData?.fieldName || "N/A"}
                        </TableCell>

                        <TableCell align="left" >
                            {/* <span dangerouslySetInnerHTML={{ __html: jsonData?.oldValue || "N/A" }} ></span> */}
                            {typeof parseJSONorString(jsonData?.oldValue) == "object" ? <ul>
                                {Object.entries(parseJSONorString(jsonData?.oldValue)).map(([key, value]) => (
                                    value.length > 0 && value && <li key={key}>
                                        {capitalizeFirstLetter(key)}: {value || "N/A"}
                                    </li>
                                ))}
                            </ul> : <span dangerouslySetInnerHTML={{ __html: jsonData?.oldValue || "N/A" }} ></span>}</TableCell>
                        <TableCell align="left">
                            {typeof parseJSONorString(jsonData?.newValue) == "object" ? <ul>
                                {Object.entries(parseJSONorString(jsonData?.newValue)).map(([key, value]) => (
                                    value.length > 0 && value && <li key={key}>
                                        {capitalizeFirstLetter(key)}: {Array.isArray(value) ? value.toString() : value || "N/A"}
                                    </li>
                                ))}
                            </ul> : <span dangerouslySetInnerHTML={{ __html: jsonData?.newValue || "N/A" }} ></span>}

                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    }

    return <>
        <Stack direction="row" alignItems="center" mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" className="page-title">
                    Audit Trails
                </Typography>
            </Stack>
        </Stack>
        <div className='row g-4'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <Box className="card mb-0 pieChart" style={{ height: 'auto' }}>
                    <Typography variant="h4" px={3} pb={2} pt={2.5} className="page-title">Campaigns Status</Typography>
                    {/* <hr /> */}
                    <ReactApexChart
                        className="p-4"
                        options={data?.options}
                        series={data?.series}
                        type="pie"
                        height={300}
                        width="100%"

                    />
                </Box>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <Box className="card mb-0 pieChart" style={{ height: 'auto' }}>
                    <Typography variant="h4" px={3} py={2} className="page-title">Influencers Status</Typography>
                    {/* <hr /> */}
                    <ReactApexChart
                        className="p-4"
                        options={influencerData?.options}
                        series={influencerData?.series}
                        type="pie"
                        height={300}
                        width="100%"
                    />
                </Box>
            </div>
        </div>
        <div className='col-12'>
            <Box className="card mb-0" mt={4}>
                <Typography variant="h4" p={3} className="page-title">
                    Activity Logs
                </Typography>
                <hr />
                <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                    <SearchInput
                        className="inputSearch sContrl p-0"
                        value={filters.search}
                        placeholder={"Search here..."}
                        handleSearch={handleSearch}
                        clearSearch={handleSearchClear}
                        startAdornment={
                            <InputAdornment className="overIcon" position="start"
                                sx={{
                                    maxHeight: 'none',
                                    height: '38px',
                                    minWidth: '38px',
                                    marginRight: '0px'
                                }}
                            >
                                <Search />
                            </InputAdornment>
                        }
                    />
                    <div className='allFilter campMgmt'>
                        <div className="d-flex align-items-center ">
                            <Stack direction={"row"} gap={0.5}>

                                <Box sx={{ width: "500px" }}>
                                    <AutoSuggestion
                                        multiple={true}
                                        key={removeUsers}
                                        disableClearable={true}
                                        values={filters.userId || []}
                                        className="auto-chips-cover"
                                        label={""}
                                        limitTags={2}
                                        onChange={(_e, newValue) => {
                                            dispatch(updateAudit({ filters: { ...filters, userId: newValue.map((v) => v.id), userIds: newValue?.map((v) => v.id)?.toString() } }));
                                            dispatch(getAllAuditListing());
                                        }}
                                        //key={filters?.userId?.length}
                                        placeholder="All Users"
                                        defaultValue={filters.userId || []}
                                        id={`users-${filters.userId?.length}`}
                                        onModalClose={() => { }}
                                        size="small"
                                        name="userId"
                                        options={userListing}
                                        isOptionEqualToValue={(option, value) => {
                                            if (
                                                value === "" ||
                                                option === "" ||
                                                value === option
                                            )
                                                return option;
                                        }}
                                        getOptionLabel={(option) =>
                                            option.adminName
                                        }
                                        renderOption={(props, option) => {
                                            return (
                                                <Box
                                                    component="li"
                                                    sx={{
                                                        "& > img": { mr: 2, flexShrink: 0 },
                                                        fontFamily: "Poppins",
                                                    }}
                                                    {...props}
                                                >
                                                    {option.adminName}
                                                </Box>
                                            );
                                        }}
                                    />
                                </Box>
                                <DateRangePickerComponent text="Select Date" monthsShown={1} getSelectedDate={(dates) => {

                                    if (dates?.filter((v) => v)?.length === 2) {
                                        dispatch(updateAudit({ filters: { ...filters, startDate: getFormatedDate(dates[0]), endDate: getFormatedDate(dates[1]) } }));
                                        dispatch(getAllAuditListing());
                                    }

                                }} dateReset={filters} style={{ position: "relative", right: 101 }} />
                                <Select
                                    value={filters.tableName || ''}
                                    name="tableName"
                                    onChange={(e) => {
                                        dispatch(updateAudit({ filters: { ...filters, tableName: e.target.value } }));
                                        dispatch(getAllAuditListing());
                                    }}
                                    displayEmpty
                                    defaultValue={""}
                                >
                                    <MenuItem value="" disabled hidden>
                                        All Records
                                    </MenuItem>

                                    {uniqueArrayByKey(triggerTable, "tableName")?.map((row) => (
                                        <MenuItem className="capitalize-first-letter" key={row.tableName} value={row.tableName}>
                                            {capitalizeFirstLetter(row?.tableName)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Tooltip title="Refresh listing">
                                    <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                                        onClick={() => {
                                            dispatch(updateAudit({ filters: {}, removeUsers: Math.random() }))
                                            dispatch(getAllAuditListing());
                                        }}
                                    />
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                </div>
                <TableContainer stickyHeader >
                    <Table sx={{ maxHeight: 400 }} stickyHeader aria-label="simple table">
                        <EnhancedTableHead
                            // numSelected={selected.length}

                            order={"test"}
                            orderBy={sort_key}
                            //onSelectAllClick={handleSelectAllClick}
                            onRequestSort={() => { }}
                            //rowCount={rows.length}
                            numSelected={0}
                            onSelectAllClick={function (

                            ) {
                                throw new Error("Function not implemented.");
                            }}
                        //order={"desc"}
                        />

                        <TableBody>
                            {listing?.length > 0 ? (
                                listing?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            className={"tableRow"}
                                            style={{ userSelect: "text" }}
                                        >
                                            <TableCell component="th" scope="row" >
                                                {row?.name}
                                            </TableCell>

                                            <TableCell align="left" className="capitalize-first-letter">
                                                {row?.table}
                                            </TableCell>

                                            <TableCell align="left" > <Link onClick={(e) => {
                                                e.preventDefault();
                                                window.open(getRedirectlink(row?.table, row.recordId, row.userId), "_blank")
                                            }}>{row.recordName}</Link></TableCell>
                                            <TableCell align="left" className="capitalize-first-letter">{row.fieldName || "N/A"}</TableCell>
                                            <TableCell align="left">{getFormatedDate(row.createdDate, 'DD MMMM YYYY hh:mm A')}</TableCell>
                                            <TableCell align="left"> <span className="viewIcon" onClick={() => {
                                                setJsonData(row)
                                                setModelOpen(true);
                                            }}>
                                                View Changes
                                            </span></TableCell>
                                        </TableRow>

                                    )
                                })) : (
                                <TableRow colSpan={10}>
                                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                        <Typography
                                            color="inherit"
                                            variant="subtitle1"
                                            alignItems={"center"}
                                            component="h3"
                                        >
                                            No Data found!
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
                <Stack
                    sx={{
                        background: 'transparent',
                    }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                        <Box className="d-flex align-items-center rowPerPage" gap={0}>
                            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                            <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                        </Box>
                        <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                            <Typography variant="subtitle2">
                                {listing && listing.length > 0
                                    ? page === 0
                                        ? `0 - ${listing.length}`
                                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                        } `
                                    : "0 - 0"}{" "}
                                &nbsp;of {totalCount} items
                            </Typography>
                            <Pagination
                                count={Math.ceil(totalPages / limit) || 0}
                                variant="outlined"
                                onChange={handleChange}
                            />

                        </Box>
                    </Box>
                </Stack>
            </Box>
        </div>

        <CommonModel
            flag={true}
            open={modelOpen}
            handleDialogClose={() => setModelOpen(false)}
            dialogTitle={"Values"}
            dialogPara={<Details />}
            nvCancel={""}
            yesHandle={() => {
                setModelOpen(false)
            }}
        />
    </>
}
export default AuditTrails;