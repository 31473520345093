import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ChatIcon from '@mui/icons-material/Chat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home'; // You can replace this with a custom image if needed
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShareIcon from '@mui/icons-material/Share';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Avatar, Box, Card, CardActions, CardContent, CardMedia, Chip, Grid, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { default as ApexCharts, default as Chart } from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getToFixed } from '../../../utils/common';
import { CAMPAIGN_TYPES, getFixedValue } from '../../../utils/constant';
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));
const PostCampaign = () => {
    const { postCampaigndata, topInfluencer, postbreakDown, formData, postAffiliateInsights } = useSelector((state) => state.campaignSlice);
    const [value, setValue] = useState();
    const [data, setData] = useState();
    const { id, infid } = useParams();


    const chartOptions1 = {
        series: [
            {
                data: [21, 22, 10, 28, 16, 21, 13, 30], // Your data values
            },
        ],
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            },
            events: {
                click: function (chart, w, e) {
                    // You can add your click event logic here
                    console.log('Chart clicked', chart, w, e);
                },
            },
        },


        colors: ['#F72585'], // Pass in the colors array
        plotOptions: {
            bar: {
                columnWidth: '45%',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#AAAAAA"]
            }
        },
        legend: {
            show: false, // Hide legend
        },
        xaxis: {
            categories: [
                ['John', 'Doe'],
                ['Joe', 'Smith'],
                ['Jake', 'Williams'],
                'Amber',
                ['Peter', 'Brown'],
                ['Mary', 'Evans'],
                ['David', 'Wilson'],
                ['Lily', 'Roberts'],
            ],
            labels: {
                style: {
                    // colors: colors, // Colors for each category label
                    fontSize: '12px', // Font size for category labels
                },
            },
        },
    };



    const chartOptions = {
        chart: {
            type: 'donut',
            toolbar: {
                show: false,
            }
        },
        labels: ['Followers', 'Non Followers'], // Labels for the chart segments
        colors: ['#FF5722', '#FF0080'], // Colors for each segment
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + '%';
            },
        },
        legend: {
            show: true,
            position: 'bottom',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%', // Adjust size of the donut
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            fontSize: '22px',
                            fontWeight: 600,
                            color: '#373d3f',
                            formatter: function (w) {
                                return '90%'; // Custom value to show in the center
                            },
                        },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 180,
                options: {
                    chart: {
                        width: 100,
                    },
                    legend: {
                        position: 'bottom',
                    }
                },
            },
        ],
    };

    const lineChart = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: '',
            align: 'left',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        },
        yaxis: {
            title: {
                text: 'Value',
            },
        },
        legend: {
            horizontalAlign: 'left'
        }
    };
    const series = [
        {
            name: 'Sales',
            data: [30, 40, 35, 50, 49, 60, 70],
        },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(postCampaigndata?.length > 0 && postCampaigndata[0]?.mediaProductType);
        setData(postCampaigndata?.length > 0 && postCampaigndata[0])
    }, [postCampaigndata]);


    return (
        <>

            <Grid container spacing={2}  >
                <Grid item xs={12} md={12} mt={2} lg={12} sm={12} >
                    <Typography variant="h5" > Detail Breakdown</Typography>
                </Grid>
                {/* Publish Shortlisted Stats */}
                {infid ? <Grid item xs={6} md={4} lg={4} sm={12}>
                    <Card
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '12px',
                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                            width: 'auto',
                            height: '70px',
                        }}
                    >
                        <Grid container alignItems="center" spacing={2}>
                            {/* Avatar/Image on the left */}
                            <Grid item>
                                <Avatar
                                    src="/budget.png"  // Update the image source to the correct path
                                    alt="budget"
                                    sx={{
                                        width: 50,
                                        height: 50,
                                    }}
                                />
                            </Grid>

                            {/* Text content */}
                            <Grid item xs>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ color: 'gray', fontSize: '14px' }}
                                >
                                    Spent / Total Budget
                                </Typography>
                                <Typography variant="subtitle">Budget Spent</Typography>
                            </Grid>

                            {/* Rating on the right */}
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontWeight: 'bold',
                                        pt: 4
                                    }}
                                >
                                    {postbreakDown?.bugget || 0}/{postbreakDown?.budget || 0}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid> : <>
                    <Grid item xs={6} md={4} lg={4} sm={12}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '12px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                width: 'auto',
                                height: '70px',
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                {/* Avatar/Image on the left */}
                                <Grid item>
                                    <Avatar
                                        src="/infl.png" // Update the image source to the correct path
                                        alt="Influencer Live"
                                        sx={{
                                            width: 50,
                                            height: 50,
                                        }}
                                    />
                                </Grid>

                                {/* Text content */}
                                <Grid item xs>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: 'gray', fontSize: '14px' }}
                                    >
                                        Publish / Shortlisted
                                    </Typography>
                                    <Typography variant="subtitle">Influencer Live</Typography>
                                </Grid>

                                {/* Rating on the right */}
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontWeight: 'bold',
                                            pt: 4
                                        }}
                                    >
                                        {postbreakDown?.liveInfluencer || 0}/{postbreakDown?.totalInfluencer || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {formData.type !== CAMPAIGN_TYPES.AFFILIATE && <Grid item xs={6} md={4} lg={4} sm={12}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '12px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                width: 'auto',
                                height: '70px',
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                {/* Avatar/Image on the left */}
                                <Grid item>
                                    <Avatar
                                        src="/video.png" // Update the image source to the correct path
                                        alt="Influencer Live"
                                        sx={{
                                            width: 50,
                                            height: 50,
                                        }}
                                    />
                                </Grid>



                                {/* Text content */}


                                <Grid item xs>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: 'gray', fontSize: '14px' }}
                                    >
                                        Publish / Shortlisted
                                    </Typography>
                                    <Typography variant="subtitle">Video Live</Typography>
                                </Grid>


                                {/* Rating on the right */}
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontWeight: 'bold',
                                            pt: 4
                                        }}
                                    >
                                        {postbreakDown?.liveVedio || 0}/{postbreakDown?.totalVedio || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                    <Grid item xs={6} md={4} lg={4} sm={12}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '12px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                width: 'auto',
                                height: '70px',
                            }}
                        >
                            <Grid container alignItems="center" spacing={2}>
                                {/* Avatar/Image on the left */}
                                <Grid item>
                                    <Avatar
                                        src="/budget.png" // Update the image source to the correct path
                                        alt="Influencer Live"
                                        sx={{
                                            width: 50,
                                            height: 50,
                                        }}
                                    />
                                </Grid>

                                {/* Text content */}
                                <Grid item xs>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ color: 'gray', fontSize: '14px' }}
                                    >
                                        Publish / Shortlisted
                                    </Typography>
                                    <Typography variant="subtitle">Budget Spent</Typography>
                                </Grid>

                                {/* Rating on the right */}
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            fontWeight: 'bold',
                                            pt: 4
                                        }}
                                    >
                                        {postbreakDown?.bugget || 0}/{postbreakDown?.budget || 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid></>}

                {formData.type == CAMPAIGN_TYPES.AFFILIATE &&
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Event Name </TableCell>
                                        <TableCell >Count</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {postAffiliateInsights?.details?.length > 0 ? postAffiliateInsights?.details?.map((row, i) => (
                                        <TableRow key={row.c_id}>
                                            <TableCell>{row?.event_name}</TableCell>
                                            <TableCell>{row?.count}</TableCell>
                                        </TableRow>
                                    )) : <TableRow><TableCell colSpan={4} align='center'  >No History Found</TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }


                {postCampaigndata?.length > 0 && <Grid item xs={12} md={12} lg={12} sm={12} >
                    <TabContext value={value} bgcolor="#FAFAFA">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: "#FAFAFA" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                {postCampaigndata?.length > 0 && postCampaigndata?.map((v, i) => <Tab label={v.mediaProductType} onClick={() => setData(v)} key={i} value={v.mediaProductType} />)}
                            </TabList>
                        </Box>

                        <TabPanel value={data?.mediaProductType} sx={{ padding: 0, pt: 2 }}>
                            {infid && <Typography mb={2} gutterBottom>
                                Deliverable link: <Link to={data?.deliverableLink || "/"}>{data?.deliverableLink || "N/A"}</Link>
                            </Typography>}
                            <Stack direction={"row"} gap={4}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '16px',
                                        borderRadius: '5px',
                                        width: '220px',
                                        height: '130px',
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>



                                        {/* Avatar with icon or image */}
                                        <Grid item>
                                            <Avatar sx={{ backgroundColor: '#f0f0f0', width: 30, height: 30 }}>
                                                <HomeIcon sx={{ color: '#000' }} fontSize='small' />
                                                {/* You can replace HomeIcon with an Image component if needed */}
                                            </Avatar>
                                        </Grid>

                                        {/* Text below the icon */}
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'gray', fontSize: '14px' }}
                                            >
                                                Plays
                                            </Typography>
                                        </Grid>

                                        {/* Large number display */}
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                }}
                                            >
                                                {getFixedValue(parseFloat(data?.ig_reels_aggregated_all_plays_count)) || "N/A"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '16px',
                                        borderRadius: '5px',
                                        width: '220px',
                                        height: '130px',
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                        {/* Avatar with icon or image */}
                                        <Grid item>
                                            <Avatar sx={{ backgroundColor: '#f0f0f0', width: 30, height: 30 }}>
                                                <PlayArrowIcon sx={{ color: '#000' }} fontSize='small' />
                                                {/* You can replace HomeIcon with an Image component if needed */}
                                            </Avatar>
                                        </Grid>

                                        {/* Text below the icon */}
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'gray', fontSize: '14px' }}
                                            >
                                                Initial Plays
                                            </Typography>
                                        </Grid>

                                        {/* Large number display */}
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                }}
                                            >
                                                {getToFixed(parseFloat(data?.plays || 0)) || "N/A"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '16px',
                                        borderRadius: '5px',
                                        width: '220px',
                                        height: '130px',
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                        {/* Avatar with icon or image */}
                                        <Grid item>
                                            <Avatar sx={{ backgroundColor: '#f0f0f0', width: 30, height: 30 }}>
                                                <PersonOutlineIcon sx={{ color: '#000' }} fontSize='small' />
                                                {/* You can replace HomeIcon with an Image component if needed */}
                                            </Avatar>
                                        </Grid>

                                        {/* Text below the icon */}
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'gray', fontSize: '14px' }}
                                            >
                                                Replays
                                            </Typography>
                                        </Grid>

                                        {/* Large number display */}
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                }}
                                            >
                                                {data?.clips_replays_count ? getFixedValue(parseFloat(data?.clips_replays_count)) : "N/A"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '16px',
                                        borderRadius: '5px',
                                        width: '220px',
                                        height: '130px',
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                        {/* Avatar with icon or image */}
                                        <Grid item>
                                            <Avatar sx={{ backgroundColor: '#f0f0f0', width: 30, height: 30 }}>
                                                <LogoutIcon sx={{ color: '#000' }} fontSize='small' />
                                                {/* You can replace HomeIcon with an Image component if needed */}
                                            </Avatar>
                                        </Grid>

                                        {/* Text below the icon */}
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'gray', fontSize: '14px' }}
                                            >
                                                Watch Time
                                            </Typography>
                                        </Grid>

                                        {/* Large number display */}
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                }}
                                            >
                                                {data?.ig_reels_video_view_total_time ? getToFixed(parseFloat(data?.ig_reels_video_view_total_time)) / 1000 : "N/A"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '16px',
                                        borderRadius: '5px',
                                        width: '220px',
                                        height: '130px',
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                        {/* Avatar with icon or image */}
                                        <Grid item>
                                            <Avatar sx={{ backgroundColor: '#f0f0f0', width: 30, height: 30 }}>
                                                <LocalPizzaIcon sx={{ color: '#000' }} fontSize='small' />
                                                {/* You can replace HomeIcon with an Image component if needed */}
                                            </Avatar>
                                        </Grid>

                                        {/* Text below the icon */}
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: 'gray', fontSize: '14px' }}
                                            >
                                                Avg Watch Time
                                            </Typography>
                                        </Grid>

                                        {/* Large number display */}
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                }}
                                            >
                                                {data?.ig_reels_avg_watch_time ? getToFixed(parseFloat(data?.ig_reels_avg_watch_time)) / 1000 : "N/A"}

                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Stack>
                            {/* Hashtags */}
                            <Grid container spacing={2} mt={2} >
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Paper elevation={0} sx={{ p: 1, pl: 2 }}>

                                        <Typography variant="body2" sx={{ color: "#AAAAAA" }} gutterBottom>Hashtags</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                            {data?.hashtag?.split(",")?.map((v) => <Chip label={v} sx={{ bgcolor: "#EEEEEE !important", color: "#000000 !important" }} />)}

                                            {/* Add more Chips as needed */}
                                        </Box>
                                    </Paper>
                                </Grid>

                                <Grid item xs={6} md={4} lg={4} sm={12}>
                                    <Paper elevation={0} sx={{ p: 2, textAlign: 'center' }}>
                                        <Stack direction={"row"} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Reel Interaction</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}></Typography>
                                        </Stack>
                                        <Stack direction={"row"} gap={2} >
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    bgcolor: "#EEEEEE",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '16px',
                                                    borderRadius: '5px',
                                                    width: '170px',
                                                    height: '130px',
                                                    border: "none"
                                                }}
                                            >
                                                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                    {/* Avatar with icon or image */}
                                                    <Grid item>
                                                        <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                            <FavoriteIcon sx={{ color: '#000' }} fontSize='small' />

                                                            {/* You can replace HomeIcon with an Image component if needed */}
                                                        </Avatar>
                                                    </Grid>

                                                    {/* Text below the icon */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{ color: 'gray', fontSize: '14px' }}
                                                        >
                                                            Like
                                                        </Typography>
                                                    </Grid>

                                                    {/* Large number display */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                fontSize: '36px',
                                                            }}
                                                        >
                                                            {data?.likes ? getFixedValue(parseFloat(data?.likes)) : "N/A"}


                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    bgcolor: "#EEEEEE",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '16px',
                                                    borderRadius: '5px',
                                                    width: '170px',
                                                    height: '130px',
                                                    border: "none"
                                                }}
                                            >
                                                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                    {/* Avatar with icon or image */}
                                                    <Grid item>
                                                        <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                            <ChatIcon sx={{ color: '#000' }} fontSize='small' />
                                                            {/* You can replace HomeIcon with an Image component if needed */}
                                                        </Avatar>
                                                    </Grid>

                                                    {/* Text below the icon */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{ color: 'gray', fontSize: '14px' }}
                                                        >
                                                            Comments
                                                        </Typography>
                                                    </Grid>

                                                    {/* Large number display */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                fontSize: '36px',
                                                            }}
                                                        >
                                                            {data?.comments ? getFixedValue(parseFloat(data?.comments)) : "N/A"}

                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Stack>
                                        <Stack direction={"row"} gap={2} >
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    bgcolor: "#EEEEEE",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '16px',
                                                    borderRadius: '5px',
                                                    width: '170px',
                                                    height: '130px',
                                                    border: "none"
                                                }}
                                            >
                                                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                    {/* Avatar with icon or image */}
                                                    <Grid item>
                                                        <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                            <BookmarkBorderIcon sx={{ color: '#000' }} fontSize='small' />
                                                            {/* You can replace HomeIcon with an Image component if needed */}
                                                        </Avatar>
                                                    </Grid>

                                                    {/* Text below the icon */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{ color: 'gray', fontSize: '14px' }}
                                                        >
                                                            Saves
                                                        </Typography>
                                                    </Grid>

                                                    {/* Large number display */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                fontSize: '36px',
                                                            }}
                                                        >
                                                            {data?.saved ? getFixedValue(parseFloat(data?.saved)) : "N/A"}

                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    bgcolor: "#EEEEEE",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '16px',
                                                    borderRadius: '5px',
                                                    width: '170px',
                                                    height: '130px',
                                                    border: "none"
                                                }}
                                            >
                                                <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                    {/* Avatar with icon or image */}
                                                    <Grid item>
                                                        <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                            <ShareIcon sx={{ color: '#000' }} fontSize='small' />
                                                            {/* You can replace HomeIcon with an Image component if needed */}
                                                        </Avatar>
                                                    </Grid>

                                                    {/* Text below the icon */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{ color: 'gray', fontSize: '14px' }}
                                                        >
                                                            Shares
                                                        </Typography>
                                                    </Grid>

                                                    {/* Large number display */}
                                                    <Grid item>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                fontSize: '36px',
                                                            }}
                                                        >
                                                            {data?.shares ? getFixedValue(parseFloat(data?.shares)) : "N/A"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Stack>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} md={4} lg={4} sm={12}>
                                    <Paper elevation={0} sx={{ p: 2, textAlign: 'center' }}>
                                        <Typography variant="h5" > Profile Activity ({getFixedValue(parseFloat(data?.profile_activity))})</Typography>

                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Profile Visits</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{getFixedValue(parseFloat(data?.profile_visits)) || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Bio Link Clicked</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.bio_link_clicked || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Call</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.call || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Direction</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.direction || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Email</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.email || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Other</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.other || "N/A"}</Typography>
                                        </Stack>
                                        <Stack direction={"row"} mb={1} justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography variant="subtitle1" >Text</Typography>
                                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>{data?.text || "N/A"}</Typography>
                                        </Stack>
                                        {false && <>
                                            <Stack direction={"row"} gap={2} >
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        bgcolor: "#EEEEEE",
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '16px',
                                                        borderRadius: '5px',
                                                        width: '170px',
                                                        height: '130px',
                                                        border: "none"
                                                    }}
                                                >
                                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                        {/* Avatar with icon or image */}
                                                        <Grid item>
                                                            <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                                <FavoriteIcon sx={{ color: '#000' }} fontSize='small' />
                                                                {/* You can replace HomeIcon with an Image component if needed */}
                                                            </Avatar>
                                                        </Grid>

                                                        {/* Text below the icon */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: 'gray', fontSize: '14px' }}
                                                            >
                                                                Initial Plays
                                                            </Typography>
                                                        </Grid>

                                                        {/* Large number display */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: '36px',
                                                                }}
                                                            >
                                                                {getToFixed(parseFloat(data?.plays || 0)) || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        bgcolor: "#EEEEEE",
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '16px',
                                                        borderRadius: '5px',
                                                        width: '170px',
                                                        height: '130px',
                                                        border: "none"
                                                    }}
                                                >
                                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                        {/* Avatar with icon or image */}
                                                        <Grid item>
                                                            <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                                <ChatIcon sx={{ color: '#000' }} fontSize='small' />
                                                                {/* You can replace HomeIcon with an Image component if needed */}
                                                            </Avatar>
                                                        </Grid>

                                                        {/* Text below the icon */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: 'gray', fontSize: '14px' }}
                                                            >
                                                                Replays
                                                            </Typography>
                                                        </Grid>

                                                        {/* Large number display */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: '36px',
                                                                }}
                                                            >
                                                                {getFixedValue(parseFloat(data?.clips_replays_count)) || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Stack>
                                            <Stack direction={"row"} gap={2} >
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        bgcolor: "#EEEEEE",
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '16px',
                                                        borderRadius: '5px',
                                                        width: '170px',
                                                        height: '130px',
                                                        border: "none"
                                                    }}
                                                >
                                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                        {/* Avatar with icon or image */}
                                                        <Grid item>
                                                            <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                                <BookmarkBorderIcon sx={{ color: '#000' }} fontSize='small' />
                                                                {/* You can replace HomeIcon with an Image component if needed */}
                                                            </Avatar>
                                                        </Grid>

                                                        {/* Text below the icon */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: 'gray', fontSize: '14px' }}
                                                            >
                                                                Watch Time
                                                            </Typography>
                                                        </Grid>

                                                        {/* Large number display */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: '36px',
                                                                }}
                                                            >
                                                                {data?.ig_reels_video_view_total_time ? getToFixed(parseFloat(data?.ig_reels_video_view_total_time)) / 1000 : "N/A"}

                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        bgcolor: "#EEEEEE",
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '16px',
                                                        borderRadius: '5px',
                                                        width: '170px',
                                                        height: '130px',
                                                        border: "none"
                                                    }}
                                                >
                                                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                                                        {/* Avatar with icon or image */}
                                                        <Grid item>
                                                            <Avatar sx={{ backgroundColor: 'white', width: 30, height: 30 }}>
                                                                <ShareIcon sx={{ color: '#000' }} fontSize='small' />``
                                                                {/* You can replace HomeIcon with an Image component if needed */}
                                                            </Avatar>
                                                        </Grid>

                                                        {/* Text below the icon */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{ color: 'gray', fontSize: '14px' }}
                                                            >
                                                                Avg. Watch Time
                                                            </Typography>
                                                        </Grid>

                                                        {/* Large number display */}
                                                        <Grid item>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: '36px',
                                                                }}
                                                            >
                                                                {data?.ig_reels_avg_watch_time ? getToFixed(parseFloat(data?.ig_reels_avg_watch_time)) / 1000 : "N/A"}

                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Stack></>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} md={4} lg={4} sm={12}></Grid>
                            </Grid>
                        </TabPanel>

                    </TabContext>
                </Grid>
                }






                {/* Reached and Reel Interactions */}
                {false && <Grid item xs={6} md={4} lg={4} sm={12}>
                    <Paper elevation={0} sx={{ p: 2, textAlign: 'center' }}>

                        <Stack direction={"row"} justifyContent="space-between"
                            alignItems="center" >
                            <Typography variant="subtitle1" >Reached</Typography>
                            <Typography variant="subtitle1" justifyContent={"right"} textAlign={"right"}>1254</Typography>
                        </Stack>
                        <Chart
                            options={chartOptions}
                            series={[60, 40]}
                            type="donut"
                            width="380"
                        />
                    </Paper>
                </Grid>}


                {/* Charts (Growth and Daily Views) */}
                {false && <><Grid item xs={6} md={6} lg={6} sm={6}>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Typography variant="h6">Growth</Typography>
                        <ApexCharts options={lineChart} series={series} type="line" height={350} />

                    </Paper>
                </Grid>
                    <Grid item xs={6} md={6} lg={6} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Typography variant="h6">Daily Views</Typography>
                            <Chart options={chartOptions1} series={chartOptions1.series} type="bar" height={350} />

                        </Paper>
                    </Grid></>}
                {/* Charts End */}

                <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Typography variant="h6">Top Influencers</Typography>
                </Grid>
                {topInfluencer?.length > 0 ? topInfluencer?.map((v) => {
                    return <Grid item xs={12} md={3} lg={3} sm={12}>
                        <Card elevation={0}   >
                            <CardMedia
                                sx={{ height: 140 }}
                                image={v?.picture ? v?.picture : "/"}
                                title={v?.name || "N/A"}
                            />
                            <CardContent>
                                <Typography textAlign={"center"} mb={1} gutterBottom variant="h4" component="h4">
                                    {v?.name || "N/A"}
                                </Typography>
                                <Typography variant="subtitle" sx={{ mt: "1px !important", }}>
                                    📍  {v?.address || "N/A"} <span style={{ float: "right", cursor: "pointer" }}>
                                        💼 {v?.profileNiche?.length > 0 ? <BootstrapTooltip arrow title={v?.profileNiche?.map((v) => v.option)?.toString()} placement="top">{v?.profileNiche[0].option}</BootstrapTooltip> : "N/A"}
                                    </span>
                                </Typography>


                            </CardContent>

                            <Box borderBottom={2} mt={1} color={"#EEEEEE"}></Box>
                            <CardActions mt={0} >
                                {v?.type == "affiliate" ?
                                    <Stack direction={"row"} gap={6} justifyContent="space-between" width="100%">
                                        <Typography gutterBottom variant="subtitle" >
                                            {v.event_name || 'N/A'}
                                        </Typography>
                                        <Typography variant="h5" textAlign={"right"} >
                                            {v.count || 0}
                                        </Typography>
                                    </Stack> : <Stack direction={"row"} gap={6}>
                                        <Stack direction={"column"}>
                                            <Typography gutterBottom variant="subtitle" >
                                                Followers
                                            </Typography>
                                            <Typography variant="h5" textAlign={"center"} >
                                                {getFixedValue(v?.overview?.followers)}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Typography gutterBottom variant="subtitle" >
                                                Posts
                                            </Typography>
                                            <Typography variant="h5" textAlign={"center"} >
                                                {getFixedValue(v?.Engagement?.posts?.total_posts)}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"column"}>
                                            <Typography gutterBottom variant="subtitle" >
                                                Avg. Likes
                                            </Typography>
                                            <Typography variant="h5" textAlign={"center"} >
                                                {getFixedValue(v?.Engagement?.posts?.avg_likes)}
                                            </Typography>
                                        </Stack>
                                    </Stack>}
                            </CardActions>
                        </Card>

                    </Grid>
                }) : <Grid item xs={12} md={12} lg={12} sm={12}> No influencer found</Grid>}


                {false && <Grid item xs={12} md={3} lg={3} sm={12}>
                    <Card elevation={0} >
                        <CardMedia
                            sx={{ height: 140 }}
                            image="/img11.png"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography textAlign={"center"} mb={1} gutterBottom variant="h4" component="h4">
                                Sarina Malik
                            </Typography>
                            <Typography variant="subtitle" sx={{ mt: "1px !important", }}>
                                📍 Delhi, India <span style={{ float: "right" }}>
                                    💼 Fashion
                                </span>
                            </Typography>

                            <Box borderBottom={2} mt={1} color={"#EEEEEE"}></Box>
                        </CardContent>
                        <CardActions >
                            <Stack direction={"row"} gap={6}>
                                <Stack direction={"column"}>
                                    <Typography gutterBottom variant="subtitle" >
                                        Followers
                                    </Typography>
                                    <Typography variant="h5" textAlign={"center"} >
                                        4M
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"}>
                                    <Typography gutterBottom variant="subtitle" >
                                        Posts
                                    </Typography>
                                    <Typography variant="h5" textAlign={"center"} >
                                        2.5K
                                    </Typography>
                                </Stack>
                                <Stack direction={"column"}>
                                    <Typography gutterBottom variant="subtitle" >
                                        Avg. Likes
                                    </Typography>
                                    <Typography variant="h5" textAlign={"center"} >
                                        4B
                                    </Typography>
                                </Stack>
                            </Stack>
                        </CardActions>
                    </Card>

                </Grid>}
            </Grid ></>
    );
};

export default PostCampaign;
