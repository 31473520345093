import {
    Search
} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';

import { Box, Button, Checkbox, FormControl, FormLabel, Grid, InputAdornment, List, ListItem, ListItemText, MenuItem, Select, Stack, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NormalInput from "../../../components/inputs/normalInput";
import SearchInput from "../../../components/inputs/searchInput";
import CustomTextArea from "../../../components/inputs/textarea";
import TimePickerComponent from "../../../components/time";
import Schema from "../../../schema";
import { formatDate, formatString } from "../../../utils/common";
import { DAYS, NOTIFICATION_FERQUENCY, NOTIFICATION_FERQUENCY_ENUM } from "../../../utils/constant";
import { eventList, updateNotificationEditor } from "../action";
import EmailEditor from "./email";
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "14px"
    },
}));
const NotificationEditor = () => {

    const dispatch = useDispatch();
    const [type, setType] = useState(1);
    const [events, setEvent] = useState([]);
    const [search, setSearch] = useState("");
    const { eventListing } = useSelector((state) => state.notificationSlice)

    let initialVal = {
        id: 0,
        event: "",
        title: "",
        content: "",
        image: "",
        isRecurring: [],
        selectedEvents: [],
        recurringDisplay: 0,
        day: 0,
        dynamicFields: ""
        // startDate: "",
        // endDate: "",
        // neverEnd: [],
        // time: null,
        // repeat: "",
        // weekDays: [],
        // monthlyDate: []
    }
    const [val, setValue] = useState(initialVal);



    useEffect(() => {
        dispatch(eventList(setEvent));
    }, [dispatch, type])

    return <>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={3} mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" component="h1">Notification Editor</Typography>

            </Stack>
            <Stack direction="row" className="viewDetTabs mt-0 campaignDetails">
                <button className={type == 1 ? "active" : ""}
                    onClick={() => {
                        setType(1)

                    }}
                >
                    Push Notification
                </button>
                <button className={type == 2 ? "active" : ""}
                    onClick={() => setType(2)}
                >
                    Email Notification
                </button>
            </Stack>
        </Stack>
        <Box
            p={3}
            sx={{
                backgroundColor: '#fff',
                transition: 'all .5s ease-in-out',
                position: 'relative',
                border: '1px solid rgba(170, 170, 170, 0.3)',
                borderRadius: '3px',
                boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                overflow: 'hidden',
            }}
        >
            {type == 1 ?
                <Formik
                    initialValues={val}
                    validationSchema={Schema.EditorNotification}
                    enableReinitialize
                    onSubmit={(values, { resetForm }) => {
                        dispatch(updateNotificationEditor({
                            id: values.id,
                            title: values.title,
                            content: values?.content?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') || "",
                            image: values.image,
                            isRecurring: values.isRecurring.length > 0 ? 1 : 0,
                            day: values.day,
                            event: values.event
                        }, setEvent));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                        isValid,
                        setFieldError,
                        dirty,
                    }) => {
                        console.log("errors", errors)
                        // if (values.time !== null && typeof values.time !== "object") {
                        //     const [hour, minute] = values?.time?.split(':');
                        //     if (hour && minute) {
                        //         values.time = dayjs().hour(Number(hour)).minute(Number(minute));
                        //     }
                        // }
                        return (
                            <Form>
                                <Grid container>
                                    <Grid item xs={12} lg={4} md={4} sm={12} mt={2}  >
                                        <Typography variant="contained" component="h5" mb={2} gutterBottom>System Events List<span aria-hidden="true" class="MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk">&thinsp;*</span></Typography>
                                        <List dense={true} sx={{ maxHeight: "375px" }} className="multiSelect css-h4y409-MuiList-root1" >
                                            <Box p={1} borderBottom={"1px solid #AAAAAA59"}>
                                                <SearchInput
                                                    className="inputSearch sContrl"
                                                    value={search}
                                                    style={{ width: "100%" }}
                                                    placeholder={"Search here..."}
                                                    handleSearch={(e) => {
                                                        const searchValue = e.target.value;
                                                        setSearch(searchValue);
                                                        if (searchValue) {
                                                            const regex = new RegExp(`.*${searchValue}.*`, "i");
                                                            setEvent(eventListing.filter(item => regex.test(item.title)));
                                                        } else {
                                                            setEvent(eventListing);  // Ensure `eventListing` is the full original list
                                                        }
                                                    }}

                                                    startAdornment={
                                                        <InputAdornment className="overIcon" position="start"
                                                            sx={{
                                                                maxHeight: 'none',
                                                                height: '38px',
                                                                minWidth: '38px',
                                                                marginRight: '0px'
                                                            }}
                                                        >
                                                            <Search />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Box>

                                            {events.map((v, i) => {
                                                return <ListItem key={i} sx={{ cursor: "pointer", borderBottom: '1px solid #AAAAAA59', background: values?.selectedEvents?.includes(v.id) ? "#AAAAAA59" : "" }} >
                                                    <ListItemText
                                                        name={"selectedEvents"}
                                                        value={v.id}
                                                        onClick={() => {

                                                            const selectedCategoryIndex = values.selectedEvents?.indexOf(v.id);
                                                            let selectEv = [];
                                                            if (selectedCategoryIndex === -1) {
                                                                handleChange({
                                                                    target: {
                                                                        name: "selectedEvents",
                                                                        value: [v.id] //[...values?.selectedEvents, v]
                                                                    }
                                                                });
                                                                selectEv = [v.id];
                                                                setValue((pre) => ({
                                                                    ...v,
                                                                    // weekDays: v.weekDays || JSON.stringify([]),
                                                                    // monthlyDate: v.monthlyDate || JSON.stringify([]),
                                                                    // neverEnd: v.neverEnd > 0 ? ['neverEnd'] : JSON.stringify([]),
                                                                    day: v.day ? v.day : "",
                                                                    image: v.image ? v.image : "",
                                                                    isRecurring: v.isRecurring > 0 ? ['isRecurring'] : [],
                                                                    // startDate: v.startDate ? formatDate(v.startDate) : null,
                                                                    // endDate: v.endDate ? formatDate(v.endDate) : null,
                                                                    selectedEvents: selectEv
                                                                }));
                                                            } else {
                                                                const updatedselectedEvents = values?.selectedEvents ? [...values?.selectedEvents] : [];
                                                                updatedselectedEvents.splice(selectedCategoryIndex, 1);
                                                                handleChange({
                                                                    target: {
                                                                        name: "selectedEvents",
                                                                        value: []
                                                                    }
                                                                });
                                                                selectEv = []
                                                                setValue(initialVal);
                                                            }

                                                        }}
                                                        primary={formatString(v.event)}
                                                        secondary={''}
                                                    />

                                                </ListItem>
                                            })}

                                        </List>

                                        <p></p>
                                        {errors.selectedEvents && touched.selectedEvents && (
                                            <p
                                                className="error"
                                            >
                                                {`${errors?.selectedEvents}`}
                                            </p>
                                        )}
                                        {console.log("!43123", values.isRecurring)}
                                    </Grid>
                                    <Grid item xs={12} lg={8} md={8} sm={12}   >
                                        <Grid container spacing={2} p={3} ml={1} bgcolor={"white"}>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel required focused={false}>Title</FormLabel>
                                                <NormalInput
                                                    name={"title"}
                                                    type={"text"}
                                                    isDisabled={false}
                                                    focused={false}
                                                    label={("")}
                                                    placeholder={"Title"}
                                                    sizeval="medium"
                                                    values={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.title) && errors.title}
                                                    helperText={touched.title && errors.title}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel required>Mesage</FormLabel>
                                                <CustomTextArea
                                                    name={"content"}
                                                    type={"text"}
                                                    focused={false}
                                                    label={("")}
                                                    sizeval="medium"
                                                    values={values.content}
                                                    onChange={handleChange}
                                                    placeholder=" "
                                                    multiline={true}
                                                    style={{ m: 0, p: 0, height: '50px !important' }}
                                                />
                                                {/* <Editor value={values.content} onChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            name: "content",
                                                            value: e.target.value
                                                        }
                                                    })
                                                }} /> */}
                                                {errors.content && touched.content && (
                                                    <p
                                                        className="error"
                                                    >
                                                        {`${errors?.content}`}
                                                    </p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <FormLabel >Dynamic Fields <BootstrapTooltip arrow title={"Please copy the Dynamic Field(s) along with the full syntax as given below and insert in your content body as needed."} placement="top"><InfoIcon className="blueColor" fontSize='small' color="secondary" /></BootstrapTooltip></FormLabel>

                                                <Typography>
                                                    {values.dynamicFields || "N/A"}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                <FormLabel>Push Notification Image</FormLabel>
                                                <div
                                                    style={{
                                                        border: '1px solid #aaa',
                                                        borderRadius: '8px',
                                                        padding: '6px',
                                                        marginTop: '5px'
                                                    }}
                                                >
                                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <Button
                                                            component="label"

                                                            variant="contained"
                                                            tabIndex={-1}
                                                            style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.3)", color: " rgba(0, 0, 0, 1)", fontSize: '15px', fontWeight: '500', lineHeight: '17.2px', boxShadow: 'none' }}
                                                        >
                                                            Choose File
                                                        </Button>
                                                        <input
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                opacity: 0,
                                                                cursor: 'pointer',
                                                            }}
                                                            accept="image/*"
                                                            type="file"
                                                            onChange={(e) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "image",
                                                                        value: e.target.files[0]
                                                                    }
                                                                })
                                                            }}
                                                        />

                                                        {values.image?.name && <p className="m-0">{values.image?.name}</p>}
                                                    </div>
                                                </div>
                                                <small style={{ fontSize: '11px', fontWeight: '400', color: 'rgb(108, 118, 146)', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 300x300 and 180x180.</small>

                                                {errors.logo && touched.logo && (
                                                    <p
                                                        className="error m-0"
                                                    >
                                                        {`${errors?.logo}`}
                                                    </p>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} lg={6} md={6} sm={6}>
                                                <Box sx={{

                                                }}>
                                                    {typeof values?.image == "object" && values.image !== null && <img src={URL.createObjectURL(values?.image)} style={{ width: "100%", height: "100%", borderRadius: 10 }} alt="notification" />}
                                                    {typeof values?.image !== "object" && values.image !== "" && <img src={values?.image} style={{ width: "100%", height: "100%", borderRadius: 10 }} alt="notification" />}

                                                </Box>
                                            </Grid>
                                            {console.log("1234321", values.isRecurring.length)}
                                            {values.recurringDisplay == 1 &&
                                                <Grid container borderRadius={2} spacing={2} pr={2} pb={3} ml={2} mt={2} bgcolor={"#F7F7F7"}>
                                                    <Grid item xs={12} lg={12} md={12} sm={12} >
                                                        <Typography variant="h5" mb={2} gutterBottom>
                                                            <Checkbox
                                                                size='small'
                                                                sx={{ fontSize: "10px !important" }}
                                                                value={"isRecurring"}
                                                                //disabled={true}
                                                                checked={values.isRecurring.length > 0}
                                                                name='isRecurring'
                                                                onChange={(e) => {
                                                                    handleChange({
                                                                        target: {
                                                                            name: "day",
                                                                            value: ""
                                                                        }
                                                                    })
                                                                    handleChange(e);
                                                                }}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            /> Recurring Notification
                                                        </Typography>

                                                        <hr></hr>
                                                    </Grid>
                                                    {values.isRecurring.length > 0 &&
                                                        <>
                                                            <Grid item xs={12} lg={12} md={12} sm={12} >
                                                                <Stack direction={"row"} gap={0}>
                                                                    <FormLabel sx={{ position: "relative", top: 20 }} required={false} >Every</FormLabel>
                                                                    <FormControl sx={{ m: 1, minWidth: 50 }} size="small">
                                                                        <Select
                                                                            value={values.day}
                                                                            sx={{ maxHeight: 40, borderRadius: 1 }}
                                                                            size="small"
                                                                            name="day"
                                                                            onChange={handleChange}
                                                                            displayEmpty
                                                                            defaultValue={""}
                                                                        >
                                                                            <MenuItem value="" disabled hidden>
                                                                                Select day
                                                                            </MenuItem>
                                                                            {[...Array.from({ length: 7 }, (_, i) => i + 1), ...[15, 30]].map((row) => (
                                                                                <MenuItem key={row} value={row}>
                                                                                    {row}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormLabel sx={{ position: "relative", top: 20 }} required={false}>Day(s)</FormLabel>
                                                                </Stack>

                                                                {errors.day && touched.day && (
                                                                    <p className="error" >
                                                                        {`${errors?.day}`}
                                                                    </p>
                                                                )}
                                                            </Grid>

                                                        </>

                                                    }
                                                    {values.isRecurring.length > 0 && false && <>
                                                        <Grid item xs={12} lg={6} md={6} sm={12} >
                                                            <FormLabel required>Start Date</FormLabel>
                                                            <NormalInput
                                                                name={"startDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                isRequired={false}
                                                                label={""}
                                                                sizeval="medium"
                                                                min={formatDate(new Date())}
                                                                values={values?.startDate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={
                                                                    Boolean(touched.startDate) && errors.startDate
                                                                }
                                                                helperText={touched.startDate && errors.startDate}
                                                            />

                                                        </Grid>

                                                        <Grid item xs={12} lg={6} md={6} sm={12} >
                                                            <Stack direction={"row"} gap={0.5} justifyContent="space-between"
                                                                alignItems="center"
                                                                width="100%">
                                                                <FormLabel required={!values.neverEnd.length > 0}>End Date</FormLabel>
                                                                <Box display={"flex"} gap={0.5} alignContent={"right"} justifyContent="flex-end">
                                                                    <Checkbox
                                                                        size='small'
                                                                        sx={{ fontSize: "10px !important" }}
                                                                        value={"neverEnd"}
                                                                        checked={values.neverEnd.length > 0}
                                                                        name='neverEnd'
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            setFieldValue("endDate", "");
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    /><FormLabel size="small" sx={{ fontSize: 12 }} justifyContent="right">Never Ends</FormLabel>
                                                                </Box>

                                                            </Stack>
                                                            <NormalInput
                                                                name={"endDate"}
                                                                type={"date"}
                                                                focused={false}
                                                                isRequired={false}
                                                                label={""}
                                                                isDisabled={values.neverEnd.length > 0 || !values.startDate}
                                                                sizeval="medium"
                                                                min={values?.startDate}
                                                                values={values?.endDate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={
                                                                    Boolean(touched.endDate) && errors.endDate
                                                                }
                                                                helperText={touched.endDate && errors.endDate}
                                                            />
                                                        </Grid>



                                                        <Grid item xs={12} lg={6} md={6} sm={12} >
                                                            <FormLabel required>Repeat</FormLabel>
                                                            <Select
                                                                value={values.repeat}
                                                                sx={{ width: "100%" }}
                                                                name="repeat"
                                                                onChange={handleChange}
                                                                displayEmpty
                                                                defaultValue={""}
                                                            >
                                                                <MenuItem value="" disabled hidden>
                                                                    Select
                                                                </MenuItem>
                                                                {NOTIFICATION_FERQUENCY.map((row) => (
                                                                    <MenuItem key={row} value={row}>
                                                                        {row}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.repeat && touched.repeat && (
                                                                <p className="error" >
                                                                    {`${errors?.repeat}`}
                                                                </p>
                                                            )}

                                                        </Grid>

                                                        <Grid item xs={12} lg={6} md={6} sm={12} >
                                                            <FormLabel required>Start Time</FormLabel>
                                                            <TimePickerComponent values={values?.time} handleChange={(value) => {
                                                                handleChange({
                                                                    target: {
                                                                        name: "time",
                                                                        value: value
                                                                    }
                                                                })
                                                            }} />
                                                            {errors.time && touched.time && (
                                                                <p className="error" >
                                                                    {`${errors?.time}`}
                                                                </p>
                                                            )}

                                                        </Grid>
                                                        {values.repeat === NOTIFICATION_FERQUENCY_ENUM.WEEKLY &&
                                                            <Grid item xs={12} lg={6} md={6} sm={12} >
                                                                <FormLabel required>Select Day</FormLabel>
                                                                <Stack direction="row" rowGap={2} columnGap={2} mt={1}>
                                                                    {DAYS.map((day, index) => (
                                                                        <Button
                                                                            key={index}
                                                                            size="small"
                                                                            color={values.weekDays.includes(day.value) ? "success" : "primary"}
                                                                            variant={values.weekDays.includes(day.value) ? '' : 'outlined'}
                                                                            onClick={() => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: "weekDays",
                                                                                        value: values.weekDays.includes(day.value) ? values.weekDays.filter((v) => v !== day.value) : [day.value]
                                                                                    }
                                                                                })
                                                                            }}
                                                                            sx={{
                                                                                minWidth: 30,
                                                                                height: 30,
                                                                                borderRadius: 1,
                                                                                padding: 0, // Ensure no extra padding is added
                                                                                boxSizing: 'border-box', // Consistent sizing with borders
                                                                                backgroundColor: values.weekDays.includes(day.value) ? 'primary.main' : '#FFFFFF',
                                                                                color: values.weekDays.includes(day.value) ? 'white' : '#00000099',
                                                                                border: values.weekDays.includes(day.value) ? '1px solid primary.main' : '1px solid #DDDDDD',
                                                                                '&:hover': {
                                                                                    backgroundColor: values.weekDays.includes(day.value) ? 'primary.main' : 'inherit', // Prevent background color change on hover
                                                                                },
                                                                            }}
                                                                        >
                                                                            {day.title}
                                                                        </Button>
                                                                    ))}
                                                                </Stack>
                                                                {errors.weekDays && touched.weekDays && (
                                                                    <p className="error" >
                                                                        {`${errors?.weekDays}`}
                                                                    </p>
                                                                )}
                                                            </Grid>}

                                                        {values.repeat === NOTIFICATION_FERQUENCY_ENUM.MONTHLY &&
                                                            <Grid item xs={12} lg={12} md={12} sm={12} >
                                                                <FormLabel required>Select Date</FormLabel>
                                                                <Stack direction="row" rowGap={2} columnGap={2} mt={1} flexWrap="wrap">
                                                                    {Array.from({ length: 31 }, (_, index) => index).map((day) => (
                                                                        <Button
                                                                            key={day}
                                                                            size="small"
                                                                            color={values.monthlyDate.includes(day) ? "success" : "primary"}
                                                                            variant={values.monthlyDate.includes(day) ? '' : 'outlined'}
                                                                            onClick={() => {
                                                                                handleChange({
                                                                                    target: {
                                                                                        name: "monthlyDate",
                                                                                        value: values.monthlyDate.includes(day) ? values.monthlyDate.filter((v) => v !== day) : [day]
                                                                                    }
                                                                                })
                                                                            }}
                                                                            sx={{
                                                                                minWidth: 30,
                                                                                height: 30,
                                                                                borderRadius: 1,
                                                                                padding: 0, // Ensure no extra padding is added
                                                                                boxSizing: 'border-box', // Consistent sizing with borders
                                                                                backgroundColor: values.monthlyDate.includes(day) ? 'primary.main' : '#FFFFFF',
                                                                                color: values.monthlyDate.includes(day) ? 'white' : '#00000099',
                                                                                border: values.monthlyDate.includes(day) ? '1px solid primary.main' : '1px solid #DDDDDD',
                                                                                '&:hover': {
                                                                                    backgroundColor: values.monthlyDate.includes(day) ? 'primary.main' : 'inherit', // Prevent background color change on hover
                                                                                },
                                                                            }}
                                                                        >
                                                                            {day + 1}
                                                                        </Button>
                                                                    ))}
                                                                </Stack>
                                                                {errors.monthlyDate && touched.monthlyDate && (
                                                                    <p className="error" >
                                                                        {`${errors?.monthlyDate}`}
                                                                    </p>
                                                                )}
                                                            </Grid>}
                                                    </>}
                                                </Grid>
                                            }

                                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                                <hr></hr>
                                            </Grid>

                                            <Grid item xs={12} lg={12} md={12} sm={12} className="pt-0" container justifyContent="flex-end">
                                                <Stack direction={"row"} spacing={1} pt={4}>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={!(isValid && dirty)}
                                                    >
                                                        Update
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik> : <EmailEditor />}
        </Box >
    </>

}
export default NotificationEditor