import {
    Search
} from "@mui/icons-material";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Box, InputAdornment, MenuItem, Pagination, Select, Stack, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserProfileDialog from "../../components/dialog/UserProfileKyc";
import SearchInput from '../../components/inputs/searchInput';
import PaginationSelect from '../../components/select/pagination';
import useDebounce from "../../hooks/use.debounce";
import { maskCardNumber } from '../../utils/common';
import { USER_STATUS_FILTER } from "../../utils/constant";
import { getListing } from './action';
import { updateKyc } from "./kycSlice";
function EnhancedTableHead(props) {
    const { order, orderBy } = props;
    const headCells = [

        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Full Name",
            type: "",
        },
        {
            id: "dob",
            numeric: false,
            disablePadding: true,
            label: "Date of Birth",
            type: "",
        },
        {
            id: "address",
            numeric: false,
            disablePadding: true,
            label: "Address",
            type: "",
        },
        {
            id: "Uid",
            numeric: false,
            disablePadding: true,
            label: "Aadhaar Number",
            type: "",
        },

        {
            id: "pan",
            numeric: false,
            disablePadding: true,
            label: "PAN Number",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                IconComponent={() => null}
                            //onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}


const Kyc = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modelOpen, setModelOpen] = useState(false);
    const [jsonData, setJsonData] = useState({});
    const tableRef = useRef();
    const {
        graphData,
        limit,
        page,
        search,
        sort_key,
        totalCount,
        totalPages,
        listing,
        filters,
        userListing,
        removeUsers
    } = useSelector((state) => state.kycSlice);
    const {
        triggerTable
    } = useSelector((state) => state.commonSlice)
    const debouncedSearch = useDebounce(filters.search, 500);
    useEffect(() => {
        dispatch(getListing());
    }, [])
    const handleChangePerPage = (event, value) => {
        dispatch(updateKyc({ limit: event.target.value, page: 0 }));
        dispatch(getListing());
    };
    const handleChange = (event, value) => {
        dispatch(updateKyc({ page: value - 1 }));
        dispatch(getListing());
    };
    const handleSearch = (event) => {
        if (event.target.value) {
            dispatch(updateKyc({ filters: { ...filters, search: event.target.value }, page: 0 }));
        } else {
            dispatch(updateKyc({ filters: { ...filters, search: "" }, page: 0 }));
            dispatch(getListing());
        }

    };

    const handleSearchClear = () => {
        dispatch(updateKyc({ filters: { ...filters, search: "" } }));
        dispatch(getListing());
    };
    useEffect(() => {
        if (debouncedSearch)
            dispatch(getListing())
    }, [debouncedSearch, dispatch]);




    return <>
        <Typography variant="h1" className="page-title">
            Know Your Customer (KYC)
        </Typography>

        <div className='col-12'>
            <Box className="card mb-0" mt={4}>
                <div className="myCmpny d-flex flex-wrap align-items-center justify-content-between searcFilter p-3">
                    <SearchInput
                        className="inputSearch sContrl p-0"
                        value={filters.search}
                        placeholder={"Search here..."}
                        handleSearch={handleSearch}
                        clearSearch={handleSearchClear}
                        startAdornment={
                            <InputAdornment className="overIcon" position="start"
                                sx={{
                                    maxHeight: 'none',
                                    height: '38px',
                                    minWidth: '38px',
                                    marginRight: '0px'
                                }}
                            >
                                <Search />
                            </InputAdornment>
                        }
                    />
                    <div className='allFilter campMgmt'>
                        <div className="d-flex align-items-center ">
                            <Stack direction={"row"} gap={0.5}>
                                <Select
                                    value={filters.status || ''}
                                    name="tableName"
                                    onChange={(e) => {
                                        dispatch(updateKyc({ filters: { ...filters, status: e.target.value } }));
                                        dispatch(getListing());
                                    }}
                                    displayEmpty
                                    defaultValue={""}
                                >
                                    <MenuItem value="" disabled hidden>
                                        Status
                                    </MenuItem>

                                    {USER_STATUS_FILTER.map((v) => <MenuItem key={v} value={v} >
                                        {v}
                                    </MenuItem>)}
                                </Select>
                                <Tooltip title="Refresh listing">
                                    <RotateLeftIcon toolTip="Refresh page" size="small" sx={{ cursor: "pointer", color: '#6C7692', mt: 1.5 }}
                                        onClick={() => {
                                            dispatch(updateKyc({ filters: {}, removeUsers: Math.random() }))
                                            dispatch(getListing());
                                        }}
                                    />
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                </div>
                <TableContainer stickyHeader >
                    <Table stickyHeader aria-label="simple table">
                        <EnhancedTableHead
                            // numSelected={selected.length}

                            order={"test"}
                            orderBy={sort_key}
                            //onSelectAllClick={handleSelectAllClick}
                            onRequestSort={() => { }}
                            //rowCount={rows.length}
                            numSelected={0}
                            onSelectAllClick={function (

                            ) {
                                throw new Error("Function not implemented.");
                            }}
                        //order={"desc"}
                        />

                        <TableBody>
                            {listing?.length > 0 ? (
                                listing?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            className={"tableRow"}
                                            style={{ userSelect: "text" }}
                                        >
                                            <TableCell component="th" scope="row" className="capitalize-first-letter" >
                                                {row?.name}
                                            </TableCell>

                                            <TableCell align="left" >{row.dob || "N/A"}</TableCell>

                                            <TableCell align="left" >{row.address + " " + row.city + " " + row.state}</TableCell>
                                            <TableCell align="left">{maskCardNumber(row.aadharNumber)}</TableCell>
                                            <TableCell align="left">{maskCardNumber(row.panNumber)}</TableCell>
                                            <TableCell align="left"><button className={row.status?.toLowerCase() + " capitalize-first-letter"}>{row.status}</button></TableCell>
                                            <TableCell align="left"> <span className="viewIcon" onClick={() => {
                                                setJsonData(row)
                                                setModelOpen(true);
                                            }}>
                                                View Details
                                            </span></TableCell>
                                        </TableRow>

                                    )
                                })) : (
                                <TableRow colSpan={10}>
                                    <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                        <Typography
                                            color="inherit"
                                            variant="subtitle1"
                                            alignItems={"center"}
                                            component="h3"
                                        >
                                            No Data found!
                                        </Typography>

                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
                <Stack
                    sx={{
                        background: 'transparent',
                    }}
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={1.5}>
                        <Box className="d-flex align-items-center rowPerPage" gap={0}>
                            <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                            <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                        </Box>
                        <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                            <Typography variant="subtitle2">
                                {listing && listing.length > 0
                                    ? page === 0
                                        ? `0 - ${listing.length}`
                                        : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                        } `
                                    : "0 - 0"}{" "}
                                &nbsp;of {totalCount} items
                            </Typography>
                            <Pagination
                                count={Math.ceil(totalPages / limit) || 0}
                                variant="outlined"
                                onChange={handleChange}
                            />

                        </Box>
                    </Box>
                </Stack>
            </Box>
        </div>

        <UserProfileDialog open={modelOpen} handleClose={() => setModelOpen(false)} data={jsonData} />
    </>
}
export default Kyc;